/* do-hyeon-latin-400-normal*/
@font-face {
  font-family: 'Do Hyeon';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Do Hyeon Regular'),
    local('DoHyeon-Regular'), 
    url('./files/do-hyeon-latin-400-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/do-hyeon-latin-400-normal.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('./files/do-hyeon-latin-400-normal.ttf') format('truetype');
}
